import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from '@wardpeet/gatsby-image-nextgen/compat';

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Prodotti from '../../components/prodotti'
import Newsletter from '../../components/newsletter'
import Contattami from '../../components/contattami'
import {FormattedMessage} from 'react-intl'
import {filterByLang} from '../../utility/utils'

 
const Aziende = ({ pageContext: { locale } }) => (
  <StaticQuery
    query={graphql`
      query {
        intro: allContentfulContenutoGenerico(filter: {contentful_id: {eq: "5U6VgKHnMrgRmJODWrJPpa"}}) {
          edges {
            node {
              node_locale
              titolo
              immagine {
                fluid(maxWidth:1110, quality:70) {
                    ...GatsbyContentfulFluid_withWebp
                }
                description
              } 
              claim {
                childMarkdownRemark {
                  html
                }
              }
              testo {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        art1: allContentfulContenutoGenerico(filter: {contentful_id: {eq: "6iXG0GpJ8zCpPzOAHuu4em"}}) {
          edges {
            node {
              node_locale
              testo {
                childMarkdownRemark {
                  html
                }
              }
              immagine {
                fluid(maxWidth: 450) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        } 
        art2: allContentfulContenutoGenerico(filter: {contentful_id: {eq: "b0ZZxAA84nX1Qik6RnsCA"}}) {
          edges {
            node {
              node_locale
              testo {
                childMarkdownRemark {
                  html
                }
              }
              immagine {
                fluid(maxWidth: 450) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        } 
        art3: allContentfulContenutoGenerico(filter: {contentful_id: {eq: "5AG5NUsEF8zxaADH943PW1"}}) {
          edges {
            node {
              node_locale
              testo {
                childMarkdownRemark {
                  html
                }
              }
              immagine {
                fluid(maxWidth: 450) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }  
        prodotti: allContentfulProdotti(filter: {area: {in: "illustrazioni per i tuoi prodotti"}}, sort: {fields: dataOrdinamento, order: DESC}) {
          edges {
            node{
              node_locale
              titolo
              copertina {
                description
                fluid(maxWidth: 450) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const intro = filterByLang(data.intro,locale)[0];
      const art1 = filterByLang(data.art1,locale)[0];
      const art2 = filterByLang(data.art2,locale)[0];
      const art3 = filterByLang(data.art3,locale)[0];
      const prodotti = filterByLang(data.prodotti,locale);
      return (
        <Layout locale={locale}>
          <SEO title="illustrazioneProdotti" 
               immagineHero={intro.immagine.childImageSharp}
               description="Realizzo i miei lavori per aziende che vogliono valorizzare i propri prodotti e la propria comunicazione con immagini uniche ed evocative, per l'editoria e per clienti privati che desiderano illustrazioni personalizzate per decorare la propria casa, da regalare o per progetti personali."/>
          <div className="row">
            <div className="col-12 text-center">
              <FormattedMessage id="illustrazioneProdotti">
                  {txt => <h1 className="pagetitle">{txt}</h1>}
              </FormattedMessage>
            </div>
          </div>
          <div className="section section--top">
            <div className="row">
              <div className="col-12">
                <GatsbyImage className="section__topimg" fluid={intro.immagine.fluid} />
              </div>
            </div>
        </div>
        <div className="section">
            <div className="row">
              <div className="col-12">
                {intro.claim &&
                  <div className="section__incipit section__incipit--bottom text-center"  dangerouslySetInnerHTML={{__html: intro.claim.childMarkdownRemark.html}} />
                }
                <div className="section__text"  dangerouslySetInnerHTML={{__html: intro.testo.childMarkdownRemark.html}} />
              </div>
            </div>
          </div>
          <div className="section">

            <div className="section__row">
              <div className="row">
                <div className="col-12 text-center">
                  <FormattedMessage id="comeLavoro">
                    {txt => <h2 className="section__title">{txt}</h2>}
                  </FormattedMessage>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4 col-12">
                  <GatsbyImage fluid={art1.immagine.fluid}/>
                </div>
                <div className="col-md-8 col-12">
                    <div  className="section__text" dangerouslySetInnerHTML={{__html: art1.testo.childMarkdownRemark.html}} />
                </div>
              </div>
            </div>

            <div className="section__row">
              <div className="row align-items-center">
                <div className="col-md-8 col-12 order-2 order-md-1">
                  <div  className="section__text" dangerouslySetInnerHTML={{__html: art2.testo.childMarkdownRemark.html}} />
                </div>
                <div className="col-md-4 col-12 order-1 order-md-2">
                  <GatsbyImage fluid={art2.immagine.fluid}/>
                </div>
              </div>
            </div>

            <div className="section__row">
              <div className="row align-items-center">
                <div className="col-md-4 col-12">
                  <GatsbyImage fluid={art3.immagine.fluid}/>
                </div>
                <div className="col-md-8 col-12">
                  <div  className="section__text" dangerouslySetInnerHTML={{__html: art3.testo.childMarkdownRemark.html}} />
                </div>
              </div>
            </div>
          </div>

     

          {!!prodotti &&      
            <div className="section">      
              <div className="row">
                <div className="col-12 text-center">
                  <FormattedMessage id="alcuniEsempi">
                    {txt => <h2 className="section__title">{txt}</h2>}
                  </FormattedMessage>
                </div>
              </div>
              <Prodotti prodotti={prodotti} variation="nolink" />
            </div>
          }
          
          <Contattami action="contattami2"/>

          <Newsletter/>
        </Layout>
      )
      }
    }
  />
 
)

export default Aziende
